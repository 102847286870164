import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddressBookTheme2 from "../AddressBookTheme2";
import ChangePinTheme2 from "../ChangePINTheme2";
import DashboardTheme2 from "../DashboardTheme2";
import EditProfileTheme2 from "../EditProfileTheme2";
import DownIcon from "assets/icons/down.svg";
import "./account-wrapper.scss";
// import Myorder from "../MyOrder";
import MyOrderTheme2 from "../MyOrderTheme2";
import CateringMealsOrderTheme2 from "../CateringMealsOrderTheme2";
import MyWishlistTheme2 from "../MyWishlistTheme2";
import EBTCardTheme2 from "../EBTCardTheme2";
import { getCustomerDetails } from "redux/actions";
import { useParams, useNavigate } from "react-router-dom";
import { getLocalStorageValue } from "config/helper";

const AccountArapperTheme2 = () => {
  const { ebtBalance, menu } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const ecom_enabled = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.allow_ecommerce
  );

  const mealkit_or_catering_enabled = useSelector(
    ({ common }) =>
      common.commonDetailsData.ecom_section.is_mealkit_and_catering_flag
  );

  // Changed label from PIN to Password -1630 - 07/02/2023 - Madhu
  const { is_numeric_password } = headerSectionData;
  const passwordOrPin = is_numeric_password === "1" ? "Pin" : "Password";

  const customerData = useSelector(({ customer }) => customer.customerlistData);
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const { allow_ebt } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const [tabName, setTabName] = useState("dashboardTheme2");
  const [selectDropdown, setSelectDropdown] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getCustomerDetails(body));
  }, [dispatch, clientId, memberNumber, userToken, updateProfile]); // eslint-disable-line

  useEffect(() => {
    if (ebtBalance) {
      toggleHandler("ebtInfo");
    }
  }, [ebtBalance]); // eslint-disable-line

  useEffect(() => {
    if (menu) {
      toggleHandler(menu);
    }
  }, [menu]); // eslint-disable-line

  const toggleHandler = (key) => {
    if (ebtBalance && key !== "ebtInfo") {
      navigate("/myaccount");
      setTabName(key);
      setSelectDropdown(false);
    } else {
      setTabName(key);
      setSelectDropdown(false);
    }
  };

  return (
    <div className="container">
      <div className="content-center-align">
        <div className="account-wrapper-section-theme2">
          <div className="grid">
            <div className="grid-items">
              <div className="account-tab">
                <button
                  className={tabName === "dashboardTheme2" && "active-button"}
                  onClick={(e) => toggleHandler("dashboardTheme2")}>
                  <div>
                    <i className="fa-solid fa-table-columns"></i>
                  </div>
                  <div>
                    <span>Dashboard</span>
                  </div>
                </button>
                <button
                  className={tabName === "editProfileTheme2" && "active-button"}
                  onClick={(e) => toggleHandler("editProfileTheme2")}>
                  <div>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </div>
                  <div>
                    <span>Edit Profile</span>
                  </div>
                </button>
                <button
                  className={tabName === "changePinTheme2" && "active-button"}
                  onClick={(e) => toggleHandler("changePinTheme2")}>
                  <div>
                    <i className="fa-solid fa-gear"></i>
                  </div>
                  <div>
                    <span>Change {passwordOrPin}</span>
                  </div>
                </button>
                <button
                  className={tabName === "addressBookTheme2" && "active-button"}
                  onClick={(e) => toggleHandler("addressBookTheme2")}>
                  <div>
                    <i className="fa-solid fa-address-book"></i>
                  </div>
                  <div>
                    <span>Address Book</span>
                  </div>
                </button>
                {ecom_enabled === "1" && (
                  // <button
                  //   className={tabName === "myOrder" && "active-button"}
                  //   onClick={(e) => toggleHandler("myOrder")}
                  // >
                  <button
                    className={tabName === "myOrderTheme2" && "active-button"}
                    onClick={(e) => toggleHandler("myOrderTheme2")}>
                    <div>
                      <i className="fa-solid fa-bag-shopping"></i>
                    </div>
                    <div>
                      <span>My Orders</span>
                    </div>
                  </button>
                )}
                {ecom_enabled === "1" && mealkit_or_catering_enabled === "1" && (
                  <button
                    className={
                      tabName === "cateringMealsOrdersTheme2" && "active-button"
                    }
                    onClick={(e) => toggleHandler("cateringMealsOrdersTheme2")}>
                    <div>
                      <i className="fa-solid fa-bag-shopping"></i>
                    </div>
                    <div>
                      <span>Catering/Meals Orders</span>
                    </div>
                  </button>
                )}
                {allow_ebt === 1 && (
                  <button
                    className={tabName === "ebtInfoTheme2" && "active-button"}
                    onClick={(e) => toggleHandler("ebtInfoTheme2")}>
                    <div>
                      <i className="fa-solid fa-credit-card"></i>
                    </div>
                    <div>
                      <span>EBT SNAP</span>
                    </div>
                  </button>
                )}

                {/* <button>
                  <div>
                    <i className="fa-solid fa-bag-shopping"></i>
                  </div>
                  <div>
                    <span>Track Your Order</span>
                  </div>
                </button> */}
                {/* <button>
                  <div> */}
                {/* <i className="fa-solid fa-cart-arrow-up"></i> */}
                {/* <i className="fa-brands fa-first-order-alt"></i>
                  </div>
                  <div>
                    <span>My Mealkit Order</span>
                  </div>
                </button> */}
              </div>
              <div className="mobile-view-select-show">
                <div
                  className="mobile-dropdown"
                  onClick={() => setSelectDropdown(!selectDropdown)}>
                  <input type="text" placeholder="My Account" readOnly />
                  <div className="down-icon-alignment">
                    <img src={DownIcon} alt="DownIcon" />
                  </div>
                </div>
                <div
                  className={
                    selectDropdown
                      ? "select-dropdown-design select-dropdown-show"
                      : "select-dropdown-design select-dropdown-hidden"
                  }>
                  <div className="select-dropdown-border">
                    <div
                      className="icon-text-alignment"
                      onClick={(e) => toggleHandler("dashboardTheme2")}>
                      <div>
                        <i className="fa-solid fa-table-columns"></i>
                      </div>
                      <div>
                        <span>Dashboard</span>
                      </div>
                    </div>
                    <div
                      className="icon-text-alignment"
                      onClick={(e) => toggleHandler("editProfileTheme2")}>
                      <div>
                        <i className="fa-solid fa-pen-to-square"></i>
                      </div>
                      <div>
                        <span>Edit Profile</span>
                      </div>
                    </div>
                    <div
                      className="icon-text-alignment"
                      onClick={(e) => toggleHandler("changePinTheme2")}>
                      <div>
                        <i className="fa-solid fa-gear"></i>
                      </div>
                      <div>
                        <span>Change Password</span>
                      </div>
                    </div>
                    <div
                      className="icon-text-alignment"
                      onClick={(e) => toggleHandler("addressBookTheme2")}>
                      <div>
                        <i className="fa-solid fa-address-book"></i>
                      </div>
                      <div>
                        <span>Address Book</span>
                      </div>
                    </div>
                    {ecom_enabled === "1" && (
                      // <div
                      //   className="icon-text-alignment"
                      //   onClick={(e) => toggleHandler("myOrder")}
                      // >
                      <div
                        className="icon-text-alignment"
                        onClick={(e) => toggleHandler("myOrderTheme2")}>
                        <div>
                          <i className="fa-solid fa-bag-shopping"></i>
                        </div>
                        <div>
                          <span>My Orders</span>
                        </div>
                      </div>
                    )}
                    {ecom_enabled === "1" &&
                      mealkit_or_catering_enabled === "1" && (
                        <div
                          className="icon-text-alignment"
                          onClick={(e) =>
                            toggleHandler("cateringMealsOrdersTheme2")
                          }>
                          <div>
                            <i className="fa-solid fa-bag-shopping"></i>
                          </div>
                          <div>
                            <span>Catering/Meals Orders</span>
                          </div>
                        </div>
                      )}

                    {/* my wish list condition added by kiran konda  on 19/08/2024[assigne by Keyur] */}
                    {ecom_enabled === "1" && (
                      <div
                        className="icon-text-alignment"
                        onClick={(e) => toggleHandler("myWIshlistTheme2")}>
                        <div>
                          <i className="fa-solid fa-heart"></i>
                        </div>
                        <div>
                          <span>My Wishlist</span>
                        </div>
                      </div>
                    )}
                    {allow_ebt === 1 && (
                      <div
                        className="icon-text-alignment"
                        onClick={(e) => toggleHandler("ebtInfoTheme2")}>
                        <div>
                          <i className="fa-solid fa-credit-card"></i>
                        </div>
                        <div>
                          <span>EBT SNAP</span>
                        </div>
                      </div>
                    )}

                    {/* <div className="icon-text-alignment">
                      <div>
                        <i className="fa-solid fa-bag-shopping"></i>
                      </div>
                      <div>
                        <span>Track Your Order</span>
                      </div>
                    </div> */}
                    {/* <div className="icon-text-alignment">
                      <div>
                        <i className="fa-brands fa-first-order-alt"></i>
                      </div>
                      <div>
                        <span>My Mealkit Order</span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <>
                {tabName === "dashboardTheme2" && (
                  <DashboardTheme2 customerData={customerData} />
                )}
              </>
              <>
                {tabName === "editProfileTheme2" && (
                  <EditProfileTheme2
                    customerData={customerData}
                    onUpdateProfile={setUpdateProfile}
                  />
                )}
              </>
              <>{tabName === "changePinTheme2" && <ChangePinTheme2 />}</>
              <>{tabName === "addressBookTheme2" && <AddressBookTheme2 />}</>

              {/* <>{tabName === "myOrder" && <Myorder />}</> */}
              <>{tabName === "myOrderTheme2" && <MyOrderTheme2 />}</>
              <>
                {tabName === "cateringMealsOrdersTheme2" && (
                  <CateringMealsOrderTheme2 />
                )}
              </>
              <>{tabName === "myWishlistTheme2" && <MyWishlistTheme2 />}</>
              <>
                {tabName === "ebtInfoTheme2" && (
                  <EBTCardTheme2 ebtBalance={ebtBalance} />
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccountArapperTheme2;
