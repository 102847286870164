import React, { useEffect, useState } from "react";
import "./phone-contact.scss";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useSelector } from "react-redux";
// import { constantcontactDetail } from "../../redux/actions/constantcontact";
import { useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const PhoneSubscription = () => {
  //Phone Subscription  From changes by kirankonda [Keyur] 22/08/2024
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const location = useLocation();
  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );
  const { constantContactData, constantContactLoading, constantContactError } =
    useSelector((constantContact) => constantContact?.constantContactReducer);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const breadcrumbList = [
    {
      lable: `Subscriber to ${commonDetailsData?.header_section?.client_name} Perks`,
      link: "",
      searchTag: ""
    }
  ];

  const { your_recaptcha_site_key } = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  // state for capturing input values
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    zipcode: "",
    store: "",
    is_allowed_sendsms_message_foruser: false
  });

  // state for capturing errors
  const [formError, setFormError] = useState({
    phone_number: ""
  });
  // onChange function
  const formHandling = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (name === "phone_number") {
      if (value !== "") {
        if (value?.length < 10) {
          setFormError({
            ...formError,
            [name]: "Please enter valid phonenumber"
          });
        } else {
          setFormError({
            ...formError,
            [name]: ""
          });
        }
      } else {
        setFormError({
          ...formError,
          [name]: ""
        });
      }
    }
    if (name === "zipcode") {
      if (value !== "") {
        if (value?.length < 5) {
          setFormError({
            ...formError,
            [name]: "Please enter valid zipcode"
          });
        } else {
          setFormError({
            ...formError,
            [name]: ""
          });
        }
      } else {
        setFormError({
          ...formError,
          [name]: ""
        });
      }
    }
  };

  // Allow only Numeric Values in phonenumber input
  const handleNumaricValues = (e) => {
    const { key } = e;
    if (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab"
    ) {
      return;
    }
    if (!/^\d$/.test(key)) {
      e.preventDefault();
    }
  };

  // Allow only Alphabets in Firstname and lastname inputs
  const handleAlphabetsValues = (e) => {
    const { key } = e;
    if (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab"
    ) {
      return;
    }
    if (!/^[a-zA-Z]$/.test(key)) {
      e.preventDefault();
    }
  };

  // submit function
  const handleSubmit = (e) => {
    const requiredFormData = {
      phone_number: formData.phone_number
    };
    e.preventDefault();
    const newErrorMessages = {};
    Object.keys(requiredFormData).forEach((key) => {
      if (!requiredFormData[key]) {
        newErrorMessages[key] = "This is a required field.";
      }
      if (formData.phone_number !== "") {
        if (formData.phone_number.length < 10) {
          newErrorMessages["phone_number"] = "Please enter valid phonenumber";
        }
      }
      if (formData?.zipcode !== "") {
        if (formData?.zipcode?.length < 5) {
          newErrorMessages["zipcode"] = "Please enter valid zipcode";
        }
      }
      if (recaptchaValue === "") {
        setCaptchaError("The captcha field is required");
        newErrorMessages["recaptchaValue"] = "Please enter valid zipcode";
      } else {
        setCaptchaError("");
      }
      if (Object.keys(newErrorMessages).length > 0) {
        setFormError(newErrorMessages);
        console.log("errors");
      } else {
        const reqBody = {
          RSAClientId: clientId,
          ClientStoreId: storeId,
          first_name: formData?.first_name,
          last_name: formData?.last_name,
          phone_number: formData?.phone_number
        };
        // dispatch(constantcontactDetail(reqBody));
        console.log("submit", reqBody);
        setSuccessMessage("Form Submitted Succesfully ");
      }
    });
  };

  useEffect(() => {
    if (constantContactData?.status === "success") {
      setSuccessMessage(constantContactData?.message);
      setFormData({
        email: "",
        first_name: "",
        last_name: "",
        phone_number: ""
      });
    } else if (constantContactError !== "") {
      setErrorMessage(constantContactError);
    }
  }, [constantContactData]);

  useEffect(() => {
    setSuccessMessage("");
    setErrorMessage("");
  }, [location]);

  useEffect(() => {
    if (successMessage !== "") {
      setSuccessMessage("");
    }
  }, []);
  const onChangeReCaptcha = (value) => {
    setRecaptchaValue(value);
    setCaptchaError("");
  };
  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title="Subscriber to White's Perks"
      />
      {commonDetailsData?.header_section?.allow_constant_contact_form ===
        "1" ? (
        <div className="contact-section">
          <div className="container">
            <div className="title-wrap">
              <h1>{commonDetailsData?.header_section?.client_name} Perks!</h1>
              <h2>Sign Up for SMS Alerts & Unlock Exclusive Deals!</h2>
            </div>
            <div className="contact-content">
              <div className="contact-desc">
                <p>
                  Thank you for choosing us to help you find healthy, affordable, and fun food options for your family! Sign up for our SMS alerts to receive weekly specials, exclusive “while they last” deals, and updates on events from your preferred store.
                </p>
              </div>

              <div className="form-wrapper">
                <div className="form-lable">First Name</div>
                <input
                  type="text"
                  onKeyDown={handleAlphabetsValues}
                  onChange={formHandling}
                  name="first_name"
                  value={formData?.first_name}
                  placeholder="First Name"
                  class="input-label"></input>
              </div>
              <div className="form-wrapper">
                <div className="form-lable">Last Name</div>
                <input
                  onChange={formHandling}
                  onKeyDown={handleAlphabetsValues}
                  name="last_name"
                  type="text"
                  value={formData?.last_name}
                  placeholder="Last Name"
                  class="input-label"></input>
              </div>
              <div className="form-wrapper">
                <div className="form-lable">
                  Phone Number <span title="required">*</span>
                </div>
                <input
                  onChange={formHandling}
                  onKeyDown={handleNumaricValues}
                  name="phone_number"
                  type="text"
                  value={formData?.phone_number}
                  maxlength="10"
                  placeholder='Phone number.'
                  class="input-label"></input>
              </div>
              {formError?.phone_number && (
                <div class="form-wrapper">
                  <div class="form-lable"></div>
                  <span className="error">{formError?.phone_number}</span>
                </div>
              )}
              <div class="form-wrapper">
                <div class="form-lable">Zip</div>
                <input
                  name="zipcode"
                  type="text"
                  placeholder="Zip"
                  onKeyDown={handleNumaricValues}
                  maxlength="5"
                  class="input-label"
                  onChange={formHandling}
                />
              </div>

              {formError?.zipcode && (
                <div class="form-wrapper">
                  <div class="form-lable"></div>
                  <span className="error">{formError?.zipcode}</span>
                </div>
              )}
              <div class="form-wrapper">
                <div class="form-lable">Preferred Store
                </div>
                <select
                  name="store"
                  onChange={formHandling}
                  value={formData?.store}>
                  {storeListData?.map((each) => (
                    <option>{each?.ClientStoreName}</option>
                  ))}
                </select>
              </div>
              <div className="form-wrapper">
                <div className="form-lable"></div>
                <label class="input-label">
                  <input
                    onChange={formHandling}
                    onKeyDown={handleNumaricValues}
                    type="checkbox"
                    className="checkbox"
                    value={
                      formData?.is_allowed_sendsms_message_foruser
                    }></input>
                  By entering my mobile number and checking this box I agree to
                  receive important messages from{" "}
                  {commonDetailsData?.header_section?.client_name} regarding
                  Special Offers, Weekly ads, Sales, Closeout Deals, and Limited
                  Time Offers. Msg frequency varies. Text STOP to "Number" to
                  end. Msg & Data Rates May Apply. By opting in, I authorize{" "}
                  {commonDetailsData?.header_section?.client_name} to deliver
                  SMS messages using an automatic system and I understand that I
                  am not required to opt in as a condition of shopping with
                  Fresh Value. By leaving this box unchecked you will not be
                  opted in for SMS messages at this time. A confirmation message
                  will be sent to your phone, and you will need to confirm your
                  consent before any further messages are sent. Click to read
                  Terms and Conditions and Privacy Notice.
                </label>
              </div>

              <div className="re-captcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={your_recaptcha_site_key}
                  onChange={onChangeReCaptcha}
                />
                <span style={{ color: "red" }}>{captchaError}</span>
              </div>
              {successMessage && (
                <div className="support-div">
                  <p style={{ color: "green" }}> {successMessage}</p>
                </div>
              )}
              {errorMessage && (
                <div className="support-div">
                  <p style={{ color: "red" }}> {errorMessage}</p>
                </div>
              )}
              <div className="signup-btn-wrapper">
                <button
                  class="signup-btn"
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    cursor: constantContactLoading ? "not-allowed" : "pointer"
                  }}
                  disabled={constantContactLoading}>
                  {constantContactLoading ? (
                    <span>Submitting...</span>
                  ) : (
                    <span>Sign up</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="support-div">
          <h1>Contact to support</h1>
        </div>
      )}
    </>
  );
};

export default PhoneSubscription;
