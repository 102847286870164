import React, { useEffect, useState } from "react";
import "./couponsidebar.scss";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = ({ onChange, isMobileView, setIsShowMobileFilter }) => {
  const [allcheck, setCheckall] = useState(true);
  const { searchText } = useSelector(({ search }) => search);
  const [mydealCheck, setmydealCheck] = useState(false);
  const [dealoftCheck, setdealoftCheck] = useState(false);
  const [OptionValue, setOptionValue] = useState(0);
  const navigate = useNavigate();

  const couponListsData = useSelector(({ coupon }) => coupon.couponData);
  const selectedCouponDeptSlug = decodeURIComponent(
    window.location.pathname
  ).split("/")[2];

  const isValidCouponDeptSlug = selectedCouponDeptSlug !== undefined;

  const onChangeoption = (value, text) => {
    setOptionValue(value);
    onChange(value);
    setCheckall(false);
    if (value === "10002") {
      setdealoftCheck(false);
      setmydealCheck(true);
    }
    if (value === "10001") {
      setmydealCheck(false);
      setdealoftCheck(true);
    }
    setIsShowMobileFilter && setIsShowMobileFilter();
    window.history.pushState({}, "", `/coupons/${text}`);
  };

  const onallChangeoption = (value) => {
    setOptionValue(value);
    onChange(value);
    setCheckall(true);
    setmydealCheck(false);
    setdealoftCheck(false);
    setIsShowMobileFilter && setIsShowMobileFilter();
    window.history.pushState({}, "", `/coupons`);
  };

  const couponCategoriesData = useSelector(
    ({ coupon }) => coupon.couponCategoriesData
  );

  const couponDeptFromUrlID =
    couponCategoriesData &&
    couponCategoriesData?.find(
      (each) => each.product_category_name === selectedCouponDeptSlug
    )?.product_category_id;

  // useEffect(() => {
  //   onallChangeoption("0");
  // }, [searchText]);

  const couponCategoriesDataLoading = useSelector(
    ({ coupon }) => coupon.couponCategoriesDataLoading
  );

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  return (
    <div
      className={`listing-sidebar-sticky-coupon-sidebar ${
        isMobileView ? "mobile-view" : ""
      }`}
    >
      <h2 className={`${theme_type === "1" ? "" : "h2-theme2"}`}>
        Departments
      </h2>

      <div className="sidebar-item-list">
        <div
          className={`${
            theme_type === "1"
              ? "ift-item-with-checkbox"
              : "ift-item-with-checkbox-theme2"
          }`}
          key={1}
        >
          <input type="checkbox" id="all" checked={OptionValue == 0} />
          <label htmlFor={"All"} onClick={() => onallChangeoption("0")}>
            <span>{"All Categories"}</span>
          </label>
        </div>
        <div className="spaceDiv"></div>

        {!couponCategoriesDataLoading &&
          couponCategoriesData &&
          couponCategoriesData.map((option) => {
            return (
              <>
                <div
                  className={`${
                    theme_type === "1"
                      ? "ift-item-with-checkbox"
                      : "ift-item-with-checkbox-theme2"
                  }`}
                  key={option.product_category_id}
                >
                  <input
                    type="checkbox"
                    id={option.product_category_id}
                    checked={option.product_category_id === OptionValue}
                  />

                  <label
                    htmlFor={option.product_category_name
                      .split(" ")
                      .join("_")
                      .toLowerCase()}
                    onClick={() =>
                      onChangeoption(
                        option.product_category_id,
                        option.product_category_name
                      )
                    }
                  >
                    <span>{option.product_category_name}</span>
                  </label>
                </div>
                <div className="spaceDiv"></div>
              </>
            );
          })}
        {/* // onChangeoption */}
      </div>
    </div>
  );
};

export default Sidebar;
