import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Routes from "routes";
import Header from "components/Header";
import HeaderTheme2 from "components/HeaderTheme2";
import Footer from "components/Footer";
import FooterTheme2 from "components/FooterTheme2";
import BackToTop from "components/BackToTop";
import {
  getCommonDetails,
  selectCurrentStore,
  getUserDetails,
  getClientDetails,
  getcouponDepartmentDetails
} from "redux/actions";
import { getStoreList } from "redux/actions/store";
import { useLocation } from "react-router-dom";
// import Cookie from "components/Cookie";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper";
import MobileBottomSticky from "components/MobileBottomSticky";

const Layout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const storeId = getLocalStorageValue("selected-store-id");
  const initialStore = getLocalStorageValue("selected-initial-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const theme = getLocalStorageValue("theme-name");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const aogEndPointURL = getLocalStorageValue("api-end-point-aog");

  const commonDetailsDataLoading = useSelector(
    ({ common }) => common.commonDetailsDataLoading
  );
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { clientDetailsLoading } = useSelector(({ user }) => user);
  const { is_clear_localstorage, theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );

  const couponDepartmentmenu = useSelector(
    ({ couponsDepartment }) => couponsDepartment.coupondepartmentData
  );
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);

  useEffect(() => {
    const prePageUrl = getLocalStorageValue("current_page_url") || "";
    setLocalStorageValue("pre_page_url", prePageUrl);
    setLocalStorageValue("current_page_url", pathname);
  }, [pathname]); // eslint-disable-line
  /* 
    useEffect(() => {
      if (is_clear_localstorage) {
        if (is_clear_localstorage !== getLocalStorageValue("is_clear_localstorage")
        ) {
          const store = getLocalStorageValue("selected-store-id");
          localStorage.clear();
          setLocalStorageValue("selected-store-id", store);
          setLocalStorageValue("is_clear_localstorage", is_clear_localstorage);
          window.location.reload();
        }
      }
    }, [is_clear_localstorage]); // eslint-disable-line */

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, [pathname]); // eslint-disable-line

    return null;
  };
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = window.location.hostname;
  }, [window.location.hostname]); // eslint-disable-line
  useEffect(() => {
    let subdomain = window.location.hostname;
    if (window.location.hostname == "localhost") {
      //subdomain = "www.igamarkets.com";
      //subdomain = "www.hendersonpigglywiggly.com";
      //subdomain = "www.ongrocerycart.us";
       subdomain = "www.waltsfoods.com";
      // subdomain = "www.ongroceryqa.com";
      //subdomain = "teals--market.web.app";
      //subdomain = "www.ongrocerystaging2.com";
    }
    const body = {
      subdomain: subdomain,
      // subdomain: "www.veritratechnologies.com",
      // subdomain: "dev.d1vjfwhpzfikie.amplifyapp.com",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    !clientId && !aogEndPointURL && dispatch(getClientDetails(body));
  }, [clientId]); // eslint-disable-line

  useEffect(() => {
    clientId && aogEndPointURL && dispatch(getUserDetails());
  }, [clientId, aogEndPointURL]); // eslint-disable-line

  useEffect(() => {
    if (clientId && aogEndPointURL && storeId) {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : ""
      };
      Object.keys(commonDetailsData).length === 0 &&
        dispatch(getCommonDetails(body));
    }
  }, [clientId, aogEndPointURL, storeId]); // eslint-disable-line

  useEffect(() => {
    if (clientId && aogEndPointURL && storeId) {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : ""
      };
      dispatch(getcouponDepartmentDetails(body));
    }
  }, [clientId, aogEndPointURL, storeId]); // eslint-disable-line

  useEffect(() => {
    if (clientId && aogEndPointURL) {
      const body = {
        RSAClientId: clientId,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : ""
      };
      dispatch(getStoreList(body));
    }
  }, [clientId, aogEndPointURL]); // eslint-disable-line

  useEffect(() => {
    if (!storeId && !currentStoreID && !!storeListData.length) {
      dispatch(selectCurrentStore(storeListData[0]));
      setLocalStorageValue("selected-store-id", storeListData[0].ClientStoreId);
    }
  }, [storeId, currentStoreID, storeListData]); // eslint-disable-line

  return !commonDetailsDataLoading &&
    !clientDetailsLoading &&
    clientId &&
    aogEndPointURL ? (
    <div className={theme}>
      <ScrollToTop />
      {theme_type === "2" ? <HeaderTheme2 /> : <Header />}
      <Routes />
      {theme_type === "2" ? <FooterTheme2 /> : <Footer />}

      {/* <Cookie /> */}
      <BackToTop />
      {theme_type === "2" ? <MobileBottomSticky /> : ""}
    </div>
  ) : (
    ""
  );
};

export default React.memo(Layout);
